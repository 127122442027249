import { Editor, Node } from "@tiptap/core";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import { initFlowbite } from "flowbite";
import htmx from "htmx.org";
import tippy, { Instance } from "tippy.js";
import { Filter } from "../types/filterType";

export let tiptapLabelWorkInput: Editor;
export let tiptapLabelPersonalInput: Editor;
export let tiptapProjectWorkInput: Editor;
export let tiptapProjectPersonalInput: Editor;
let popup: Instance[];

export const afterSettingsLoad = (): [Editor, Editor, Editor, Editor] => {
  initFlowbite();

  createFilterColorDropdown();
  tiptapLabelWorkInput = newTipTapSettingInput("#label-work-input", "label");
  tiptapLabelPersonalInput = newTipTapSettingInput(
    "#label-personal-input",
    "label"
  );
  tiptapProjectWorkInput = newTipTapSettingInput(
    "#project-work-input",
    "project"
  );
  tiptapProjectPersonalInput = newTipTapSettingInput(
    "#project-personal-input",
    "project"
  );
  htmx.process(document.body);
  return [
    tiptapLabelWorkInput,
    tiptapLabelPersonalInput,
    tiptapProjectWorkInput,
    tiptapProjectPersonalInput,
  ];
};

// export const afterSettingLabelNew() => {
//   tiptapLabelInput.commands.clearContent();
// }

export const newTipTapSettingInput = (
  selector: string,
  filterType: string
): Editor => {
  const editor = new Editor({
    element: document.querySelector(`${selector}`) as HTMLElement,
    extensions: [
      Text,
      Paragraph.extend({
        addKeyboardShortcuts: () => {
          return {
            Enter: () => {
              const input = document.querySelector(`${selector}`);
              input?.dispatchEvent(new Event(`new-${filterType}`));
              return true;
            },
          };
        },
      }),
      Node.create({
        name: "oneLiner",
        topNode: true,
        content: "block",
      }),
      Placeholder.configure({
        placeholder: ({}) => {
          return `New ${filterType} item`;
        },
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose dark:prose-invert prose-sm focus:outline-none prose-p:my-0 prose-li:m-0 w-full",
      },
    },
  });

  return editor;
};

export const initEditFilterName = (
  el: HTMLInputElement,
  filter: Filter
): Editor => {
  let updateTimeout: number;
  const editor = new Editor({
    element: el,
    extensions: [
      Text,
      Paragraph,
      Node.create({
        name: "oneLiner",
        topNode: true,
        content: "block",
      }),
    ],
    onUpdate: () => {
      clearTimeout(updateTimeout);
      updateTimeout = setTimeout(() => {
        el?.dispatchEvent(new Event("label-updated"));
      }, 200);
    },

    content: filter.name,
    editorProps: {
      attributes: {
        class:
          "prose dark:prose-invert prose-sm focus:outline-none prose-p:my-0 prose-p:text-gray-50 prose-p:text-sm prose-p:text-gray-50 w-full ",
      },
    },
  });

  return editor;
};

export const createFilterColorDropdown = () => {
  const dropdownPrioritDiv = document.createElement("div");
  dropdownPrioritDiv.innerHTML = JSON.parse(
    <string>(
      (<HTMLFormElement>document.querySelector("#filter-color-dropdown"))
        .textContent
    )
  );
  popup = tippy("body", {
    appendTo: () => document.body,
    content: <HTMLElement>(
      dropdownPrioritDiv.querySelector("#filter-color-menu")
    ),
    showOnCreate: false,
    interactive: true,
    trigger: "manual",
    placement: "bottom-start",
  });
};

export const initFilterColorDropdown = (btn: HTMLElement) => {
  const clickedElementRect = btn.getBoundingClientRect();
  popup[0].setProps({
    getReferenceClientRect: () => clickedElementRect,
  });
  popup[0].show();

  htmx.process(document.body);
};

export const hideColorFilterDropdown = () => {
  popup[0].hide();
};

import { initFlowbite } from "flowbite";
import htmx from "htmx.org";
import Sortable, { SortableEvent } from "sortablejs";
import { getAlpineDataByElement } from "../store";

export const afterClickupTasksLoaded = (listName?: string) => {
  if (listName) {
    (<HTMLElement>(
      document.getElementById("clickup-hierarchy-label")
    )).innerText = listName;
  }
  initClickupSortable();
};

const initClickupSortable = () => {
  const clickupTasks = document.querySelector("#clickup-tasks");
  // clickupTasks.forEach((clickupTask) => {
  Sortable.create(<HTMLElement>clickupTasks, {
    group: {
      name: "tasks",
      pull: "clone",
      put: false,
    },
    sort: false,
    animation: 100,
    delay: 100,
    delayOnTouchOnly: false,
    forceFallback: true,
    dragClass: "drag-card",
    ghostClass: "ghost-card",
    easing: "cubic-bezier(0, 0.55, 0.45, 1)",
    onEnd: (evt: SortableEvent) => {
      console.log(evt.item);
      let clickupID = getAlpineDataByElement(evt.item).clickupID;
      htmx.ajax("POST", "/api/users/tasks/clickup", {
        target: "#weekly-task-view .clickup-task-" + clickupID,
        swap: "outerHTML",
        values: {
          clickup_id: clickupID,
          index: evt.newIndex,
          panel_date: getAlpineDataByElement(evt.to).taskDate,
          view_type: Alpine.store("viewType"),
        },
      });
      // htmx.trigger(evt.item, "clickup-task-dropped", {
      //   index: evt.newIndex,
      //   panelDate: getAlpineDataByElement(evt.to).taskDate,
      // });

      // evt.clone.dispatchEvent(
      //   new CustomEvent("clickup-task-dropped", {
      //     detail: {
      //       index: evt.newIndex,
      //       panelDate: getAlpineDataByElement(evt.to).taskDate,
      //     },
      //   })
      // );
    },
  });
  // });
};

export const afterClickupLoaded = () => {
  initFlowbite();
  const clickupHierarchyDropdown = FlowbiteInstances.getInstance(
    "Dropdown",
    "clickup-hierarchy-dropdown"
  );

  document
    .querySelectorAll("#clickup-hierarchy-dropdown button")
    .forEach((el) => {
      el.addEventListener("click", () => {
        clickupHierarchyDropdown.hide();
      });
    });
};

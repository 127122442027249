import { Editor, Node } from "@tiptap/core";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Sortable, { SortableEvent } from "sortablejs";
import tippy, { Instance } from "tippy.js";
import { Action } from "../types/action";
import { decodeHtmlEntities, handleHttpError } from "../utils";
import { addNewTaskModal } from "./flowbite";

export let actionInputTiptap: Editor;
let popup: Instance[];

export const initActions = (actionsContainerID: string, taskID: string) => {
  const actionInput = document.querySelector(
    `#${actionsContainerID} .action-input`
  ) as HTMLElement;

  if (!actionInput) {
    console.error("Action input not found");
    return;
  }

  console.log("YOOO");

  actionInputTiptap = new Editor({
    element: actionInput,
    extensions: [
      Text,
      Paragraph.extend({
        addKeyboardShortcuts: () => {
          return {
            Enter: () => {
              addAction(
                actionsContainerID,
                actionInputTiptap.getText().trim(),
                taskID
              );
              return true;
            },
          };
        },
      }),
      Node.create({
        name: "oneLiner",
        topNode: true,
        content: "block",
      }),
      Placeholder.configure({
        placeholder: ({}) => {
          return "New action item";
        },
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose dark:prose-invert prose-sm focus:outline-none prose-p:my-0 prose-li:m-0 w-full",
      },
    },
  });

  Sortable.create(
    <HTMLElement>document.querySelector(`#${actionsContainerID} .task-actions`),
    {
      group: "actions",
      animation: 100,
      forceFallback: true,
      dragClass: "drag-card",
      ghostClass: "ghost-card",
      easing: "cubic-bezier(0, 0.55, 0.45, 1)",
      onEnd: (evt: SortableEvent) => {
        sortActions(evt);
      },
    }
  );

  createActionPriorityDropdown();
};

export const sortActions = async (evt: SortableEvent) => {
  const x_dataTo = Alpine.$data(evt?.item) as any;
  const resp = await fetch("/api/users/tasks/actions/sort/" + x_dataTo.id, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(<Action>{
      order_index: evt.newIndex,
    }),
  });

  const respJson = await resp.json();

  handleHttpError(resp, respJson);
};

export const initEditActionName = async (
  el: HTMLInputElement,
  action: Action
) => {
  let updateTimeout: number;
  new Editor({
    element: el,
    extensions: [
      Text,
      Paragraph,
      Node.create({
        name: "oneLiner",
        topNode: true,
        content: "block",
      }),
    ],
    onUpdate: (props) => {
      clearTimeout(updateTimeout);

      updateTimeout = setTimeout(() => {
        updateAction(el, <Action>{
          id: action.id,
          name: props.editor.getText().trim(),
        });
      }, 200);
    },

    content: action.name,
    editorProps: {
      attributes: {
        class:
          "prose dark:prose-invert prose-sm focus:outline-none prose-p:my-0 prose-p:text-gray-50 prose-p:text-sm prose-p:text-gray-50 w-full ",
      },
    },
  });
};

export const addAction = async (
  actionsContainerID: string,
  name: string,
  taskID: string
) => {
  if (name === "") {
    return;
  }
  actionInputTiptap.destroy();
  const resp = await fetch("/api/users/tasks/actions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(<Action>{
      name: name,
      task_id: taskID,
    }),
  });

  initActions(actionsContainerID, taskID);
  const respJson = await resp.json();

  handleHttpError(resp, respJson);

  document
    .querySelector(`#${actionsContainerID} .task-actions`)
    ?.insertBefore(
      decodeHtmlEntities(respJson.action),
      document.querySelector(".action-input")
    );

  document
    .querySelector(`#${actionsContainerID} .actions-progress`)
    ?.replaceWith(decodeHtmlEntities(respJson.progress));

  actionInputTiptap.commands.clearContent();
  actionInputTiptap.commands.focus();
};

export const updateAction = async (
  btn: HTMLInputElement,
  action: Action,
  taskID?: string
) => {
  btn.disabled = true;
  const resp = await fetch("/api/users/tasks/actions/" + action.id, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(action),
  });

  const respJson = await resp.json();
  btn.disabled = false;

  handleHttpError(resp, respJson);

  if (action.name !== undefined) {
    return;
  }

  let actionsContainerID = "task-container-edit";
  if (addNewTaskModal.isVisible()) {
    actionsContainerID = "task-container-new";
  }
  if (action.priority !== undefined) {
    popup[0].hide();
  }
  document
    .querySelector(`#${actionsContainerID}`)
    ?.replaceWith(decodeHtmlEntities(respJson.action));

  initActions(actionsContainerID, <string>taskID);
};

export const deleteAction = async (btn: HTMLInputElement, actionID: string) => {
  btn.disabled = true;
  const resp = await fetch("/api/users/tasks/actions/" + actionID, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const respJson = await resp.json();

  btn.disabled = false;
  handleHttpError(resp, respJson);

  document.getElementById("action-" + actionID)?.remove();
  let actionsContainerID = "task-container-edit";
  if (addNewTaskModal.isVisible()) {
    actionsContainerID = "task-container-new";
  }
  document
    .querySelector(`#${actionsContainerID} .actions-progress`)
    ?.replaceWith(decodeHtmlEntities(respJson.progress));
};

export const createActionPriorityDropdown = () => {
  const dropdownPrioritDiv = document.createElement("div");
  dropdownPrioritDiv.innerHTML = JSON.parse(
    <string>(
      (<HTMLFormElement>document.querySelector("#priorities-dropdown"))
        .textContent
    )
  );
  popup = tippy("body", {
    appendTo: () => document.body,
    content: <HTMLElement>(
      dropdownPrioritDiv.querySelector("#action-priority-menu")
    ),
    showOnCreate: false,
    interactive: true,
    trigger: "manual",
    placement: "bottom-start",
  });
};

export const initActionPriorityDropdown = (btn: HTMLElement) => {
  const clickedElementRect = btn.getBoundingClientRect();
  popup[0].setProps({
    getReferenceClientRect: () => clickedElementRect,
  });

  popup[0].show();
};

export const clearActionsSession = async () => {
  const resp = await fetch("/api/users/tasks/actions/session", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const respJson = await resp.json();

  handleHttpError(resp, respJson);

  document
    .getElementById("task-container-new")
    ?.replaceWith(decodeHtmlEntities(respJson.actions));
};

// export const getActions = async (taskID: string) => {
//   const resp = await fetch("/api/users/tasks/actions/" + taskID, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });

//   const respJson = await resp.json();

//   handleHttpError(resp, respJson);

//   return respJson;
// }

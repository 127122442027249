import { Export } from "@tiptap-pro/extension-export";
import Alpine from "alpinejs";
import htmx from "htmx.org";
import { SortableEvent } from "sortablejs";
import TomSelect from "tom-select";
import { getLabelSVGbyColor } from "../icons/label";
import { calcNumberOfTasks, dispatchTaskDoneEvent } from "./calendar";
import {
  addNewTaskModal,
  editTaskModal,
  panelDatePicker,
  tomLabel,
  tomPriority,
  tomProject,
} from "./flowbite";

import { getHashtagSVGbyColor } from "../icons/hashtag";

import { Editor, Node } from "@tiptap/core";
import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import StarterKit from "@tiptap/starter-kit";
import { prioritySuggestions } from "../const/priority";
import { getFlagSVGbyColor } from "../icons/flag";
import { getAlpineDataByID } from "../store";
import { Task } from "../types/task";
import {
  checkEmpty,
  decodeHtmlEntities,
  handleHttpError,
  newDatePicker,
  processHTMX,
} from "../utils";
import { initActions } from "./actions";
import suggestion from "./suggestion";

import Heading from "@tiptap/extension-heading";
import Highlight from "@tiptap/extension-highlight";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import * as chrono from "chrono-node";
import { formatDate } from "date-fns";

export let tiptapNewTaskName: Editor;
export let tiptapNewTaskDescription: Editor;
export let tiptapEditTaskDescription: Editor;

let updateTimeoutDesc: any;
let updateTimeoutName: any;
let removedMark = false;
export let isTipTapNewTaskNameInFocus = false;

export const getTaskStartDate = async (): Promise<string> => {
  await Alpine.nextTick();
  let taskStartDate = getAlpineDataByID("new-task-modal").task.date;
  if (!taskStartDate) {
    taskStartDate = Alpine.store("currentDate");
  }
  return taskStartDate;
};

function revalidateDailyTasks() {
  const dailyTasksView = document.getElementById("daily-task-view");
  dailyTasksView?.dispatchEvent(new Event("tasks-changed"));
}

export const addNewTask = async () => {
  const taskName = tiptapNewTaskName.getText();
  if (!taskName) {
    return;
  }

  let taskStartDate = panelDatePicker.getDate("yyyy-mm-dd");
  if (!tomPriority) return;
  const data = <Task>{
    name: tiptapNewTaskName.getHTML(),
    description: checkEmpty(tiptapNewTaskDescription.getHTML()),
    panel_date: taskStartDate,
    view_type: Alpine.store("viewType"),
    label_id: checkEmpty(checkEmpty(tomLabel.getValue())),
    project_id: checkEmpty(tomProject.getValue()),
    priority: tomPriority.getValue(),
    google_calendar: {
      calendar_id: getAlpineDataByID("new-task-modal").googleCalendar.id,
      event_id: getAlpineDataByID("new-task-modal").googleCalendar.eventID,
    },
  };

  const resp = await fetch("/api/users/tasks", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const respJson = await resp.json();

  handleHttpError(resp, respJson);

  const taskColumn = document.querySelector(
    `#task-column-${taskStartDate} .task-column`
  );
  if (taskColumn) {
    taskColumn.appendChild(decodeHtmlEntities(respJson.task));
  }

  addNewTaskModal.hide();
  calcNumberOfTasks(taskStartDate);

  revalidateDailyTasks();
};

export const updateTask = async (
  taskField: string,
  taskVal: any,
  taskID?: string
) => {
  if (!taskID) {
    const x_editTaskModal = getAlpineDataByID("edit-task-modal");
    if (!x_editTaskModal.task) {
      return;
    }
    taskID = x_editTaskModal.task.id;
  }

  if (taskField == "name" && taskVal == "") {
    return;
  }
  const body = new FormData();
  body.append(taskField, taskVal);

  const doneBtn = <HTMLButtonElement>(
    document.querySelector(`#edit-task-modal .task-done`)
  );
  if (doneBtn) {
    doneBtn.disabled = true;
  }

  const resp = await fetch("/api/users/tasks/" + taskID, {
    method: "PATCH",
    body: body,
  });

  const respJson = await resp.json();

  if (taskField == "done" || taskField == "priority") {
    if (doneBtn) doneBtn.disabled = false;

    document
      .querySelector(`#edit-task-modal .task-done`)
      ?.replaceWith(decodeHtmlEntities(respJson.task_done));

    dispatchTaskDoneEvent();
    revalidateDailyTasks();
    return;
  }
  (<HTMLElement>document.querySelector(`#task-${taskID}`)).innerHTML =
    respJson.task;

  revalidateDailyTasks();
};

//(<HTMLButtonElement>document.getElementById("add-task-btn"))

export const TaskColumnPrefix = "task-column-";

export const sortTasks = (event: SortableEvent) => {
  const taskElem = event?.item;
  const toElem = event?.to;

  const fromElem = <HTMLElement>event?.from;
  const columnFromElem = fromElem.parentElement;
  const columnToElem = <HTMLElement>toElem.parentElement;

  if (!columnFromElem || !columnToElem) {
    return;
  }

  const columnFromID = columnFromElem.id.substring(TaskColumnPrefix.length);
  const columnToID = columnToElem.id.substring(TaskColumnPrefix.length);

  calcNumberOfTasks(columnFromID);
  calcNumberOfTasks(columnToID);

  if (!taskElem) {
    return;
  }

  const x_dataTask = Alpine.$data(taskElem) as any;
  const x_dataTo = Alpine.$data(toElem) as any;

  htmx
    .ajax("POST", "/api/users/tasks/weekly/sort", {
      values: {
        id: x_dataTask.taskID,
        panel_date: x_dataTo.taskDate,
        view_type: Alpine.store("viewType"),
        order_index: event.newIndex,
      },
      swap: "none",
    })
    .then(() => {
      revalidateDailyTasks();
    });
};

export const onTaskEditModal = async (
  labelID: string,
  projectID: string,
  priority: string,
  taskID: string
) => {
  editTaskModal.show();
  window.history.pushState("", "", "/t/" + taskID);

  const taskEditModal = await getTaskEditModal(taskID);

  document.querySelector(".edit-task-skeleon")?.classList.add("hidden");

  (<HTMLElement>(
    document.querySelector("#edit-task-modal .modal-body")
  )).innerHTML = taskEditModal.editTaskModalBody;

  initActions("task-container-edit", taskID);

  initEditTaskAdvancedSelect(
    "#label-select",
    taskEditModal.labels,
    "open-label-select",
    getLabelSVGbyColor,
    "label_id",
    labelID
  );
  initEditTaskAdvancedSelect(
    "#project-select",
    taskEditModal.projects,
    "open-project-select",
    getHashtagSVGbyColor,
    "project_id",
    projectID
  );

  initEditTaskSelect(
    "#priority-select",
    getFlagSVGbyColor,
    "priority",
    priority
  );

  const datepickerEl = document.getElementById("panel-date");
  newDatePicker(datepickerEl);

  // datepickerEl?.addEventListener("changeDate", (e: Event) => {
  //   console.log(2);
  // });
  // panelDatePicker.setDate(new Date(startDate));

  processHTMX();
};

export const initEditTaskSelect = (
  selectID: string,
  getIcon: (color: string) => {},
  field: string = "",
  value: string = "low"
): TomSelect | null => {
  let dropdownFocus = false;
  if (!document.querySelector(selectID)) return null;
  const tom = new TomSelect(selectID, {
    valueField: "id",
    searchField: ["name"],
    create: false,
    options: [
      { id: "low", name: "Low", color: "gray-400" },
      { id: "normal", name: "Normal", color: "blue-400" },
      { id: "high", name: "High", color: "yellow-400" },
      { id: "urgent", name: "Urgent", color: "red-400" },
    ],

    items: [value],
    onDropdownOpen() {
      dropdownFocus = true;
    },
    onDropdownClose() {
      dropdownFocus = false;
    },
    onChange(value: string) {
      if (selectID == "#priority-select-new") {
        const mentionhashElm = document.querySelector(".mention-p");
        if (value == "") {
          mentionhashElm?.remove();
        } else {
          if (document.querySelectorAll(".mention-p").length > 0) {
            if (!mentionhashElm) return;
            mentionhashElm.textContent =
              "p" +
              prioritySuggestions.data
                .filter((val) => val.id == value)[0]
                .name.toLowerCase();

            mentionhashElm.setAttribute("data-id", value);
            return;
          }

          if (dropdownFocus) {
            tiptapNewTaskName.commands.insertContent({
              attrs: {
                id: value, // The unique ID of the mention
                label: prioritySuggestions.data
                  .filter((val) => val.id == value)[0]
                  .name.toLowerCase(), // The label to be displayed
              },
              type: "mentionp",
            });
            tiptapNewTaskName.commands.insertContent(" ");
          }
        }
      }
      if (field == "") {
        return;
      }
      updateTask(field, value);
    },
    render: {
      option: function (data: any, escape: any) {
        return `<div class="flex">${getIcon(data.color)}<span>${escape(
          data.name
        )}</span>
          </div>`;
      },
      item: function (data: any, escape: any) {
        return `<div class="flex" title="${escape(
          data.name
        )}">${getFlagSVGbyColor(data.color)}${escape(data.name)}
          </div>`;
      },
    },
  });

  return tom;
};

export const initEditTaskAdvancedSelect = (
  selectID: string,
  options: any[],
  btnID: string,
  getIcon: (color: string) => {},
  field: string = "",
  initialVal: string = ""
): TomSelect => {
  const tom = new TomSelect(selectID, {
    plugins: {
      no_backspace_delete: {},
      remove_button: {},
      dropdown_input: {},
    },
    options: options,
    items: [initialVal],
    create: false,
    valueField: "id",
    labelField: "name",
    searchField: ["name"],
    persist: true,
    preload: true,
    createOnBlur: true,
    maxItems: 1,

    onDropdownClose() {
      setTimeout(() => {
        btnOpen.disabled = false;
        isOpen = false;
      }, 200);
    },
    onChange(value: string) {
      if (selectID == "#label-select-new") {
        const mentionAtElm = document.querySelector(".mention-at");
        if (value == "") {
          mentionAtElm?.remove();
          //  TiptapNewTaskName.commands.deleteNode("mentionat")
        } else {
          if (document.querySelectorAll(".mention-at").length > 0) {
            // TiptapNewTaskName.commands.selectAll();

            // TiptapNewTaskName.commands.updateAttributes("mentionat", {
            //   id: value, // The unique ID of the mention
            //   label: tomLabel.getOption(value)?.textContent?.trim(), // The label to be displayed
            // });

            if (!mentionAtElm) return;
            mentionAtElm.textContent =
              "@" + <string>tomLabel.getOption(value)?.textContent?.trim();

            mentionAtElm.setAttribute("data-id", value);

            return;
          }
          tiptapNewTaskName.commands.insertContent({
            attrs: {
              id: value, // The unique ID of the mention
              label: tomLabel.getOption(value)?.textContent?.trim(), // The label to be displayed
            },
            type: "mentionat",
          });
          tiptapNewTaskName.commands.insertContent(" ");
        }
      }
      if (selectID == "#project-select-new") {
        const mentionhashElm = document.querySelector(".mention-hash");
        if (value == "") {
          mentionhashElm?.remove();
        } else {
          if (document.querySelectorAll(".mention-hash").length > 0) {
            // TiptapNewTaskName.commands.selectAll();
            // TiptapNewTaskName.commands.updateAttributes("mentionhash", {
            //   id: value, // The unique ID of the mention
            //   label: tomProject.getOption(value)?.textContent?.trim(), // The label to be displayed
            // });
            if (!mentionhashElm) return;
            mentionhashElm.textContent =
              "#" + <string>tomProject.getOption(value)?.textContent?.trim();

            mentionhashElm.setAttribute("data-id", value);

            return;
          }
          tiptapNewTaskName.commands.insertContent({
            attrs: {
              id: value, // The unique ID of the mention
              label: tomProject.getOption(value)?.textContent?.trim(), // The label to be displayed
            },
            type: "mentionhash",
          });
          tiptapNewTaskName.commands.insertContent(" ");
        }
      }

      if (field == "") {
        return;
      }
      if (value == "") {
        value = "0";
      }
      updateTask(field, value);
    },
    onOptionAdd(value: string | number, data: any) {
      console.log(value, data);
    },
    render: {
      option: function (data: any, escape: any) {
        return `<div class="flex">${getIcon(
          data.color ? data.color : "gray-400"
        )}<span>${escape(data.name)}</span>
          </div>`;
      },
      item: function (data: any, escape: any) {
        return `<div class="flex" title="${escape(data.name)}">${getIcon(
          data.color ? data.color : "gray-400"
        )}${escape(data.name)}
          </div>`;
      },
    },
    onItemAdd: function () {
      isOpen = false;
    },
  });

  let isOpen = false;
  const btnOpen = <HTMLButtonElement>document.getElementById(btnID);
  btnOpen?.addEventListener("click", (evt: Event) => {
    (<HTMLButtonElement>evt.target).disabled = true;
    if (isOpen) {
      tom.close();
      isOpen = false;
      btnOpen.disabled = false;
      return;
    }
    tom.open();
    isOpen = tom.isOpen;
  });

  return tom;
  // (<HTMLFormElement>(
  //   document.querySelectorAll("#task-edit .modal-body")[0]
  // )).innerHTML = "";
  // Init TipTap
};

export const openEditTaskModal = () => {
  const taskID = extractUUIDFromURL();
  console.log(document.querySelector("#task-" + taskID + " > div"));
  if (!taskID) {
    return;
  }
  (
    document.querySelector("#task-" + taskID + " > div") as HTMLElement
  )?.click();
};

const getTaskEditModal = async (taskID: string): Promise<any> => {
  const resp = await fetch(
    "/api/users/tasks/weekly/edit-modal?" +
      new URLSearchParams({
        id: taskID,
        view_type: String(Alpine.store("viewType")),
      }),
    {
      method: "GET",
    }
  );

  return await resp.json();
};

const extractUUIDFromURL = (): string | null => {
  const url = window.location.href;
  const regex =
    /\/t\/([0-9a-fA-F-]{8}-[0-9a-fA-F-]{4}-[0-9a-fA-F-]{4}-[0-9a-fA-F-]{4}-[0-9a-fA-F-]{12})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export const initTipTapTaskDescription = (
  content: string = "",
  update: boolean = true,
  selector: string
) => {
  const tipTapEditorElm = document.querySelector(selector);
  if (!tipTapEditorElm) {
    throw new Error(`No element with class '${selector}' found`);
  }

  const tiptapEditor = new Editor({
    element: tipTapEditorElm,
    extensions: [
      // Heading.configure({
      //   HTMLAttributes: {
      //     class: "my-custom-heading",
      //   },
      // }),
      StarterKit,
      TaskList.configure({
        HTMLAttributes: {
          class: "taskList list-none p-0",
        },
      }),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class:
            "taskItem flex gap-2 m-0 [&:not(:has(ul))]:items-center [&_ul]:m-0",
        },
      }),
      Placeholder.configure({
        placeholder: "Description...",
      }),
      Export.configure({
        appId: "29qnd3j9",
        token:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjY1NDA3NjYsIm5iZiI6MTcyNjU0MDc2NiwiZXhwIjoxNzI2NjI3MTY2LCJpc3MiOiJodHRwczovL2Nsb3VkLnRpcHRhcC5kZXYiLCJhdWQiOiJmYmMyNGIxMS05ZGMzLTQ2OWUtOTgzMy1lNjFkMjkwMWYzZWQifQ.qpLkC-tJPWdclOobtARKZg9IIhM2XjfTjIrkkPkIs_o",
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose dark:prose-invert prose-sm focus:outline-none prose-p:my-0 prose-li:m-0",
      },
    },
    content: new DOMParser().parseFromString(content, "text/html").body
      .textContent,
    onUpdate(props) {
      if (!update) {
        return;
      }
      clearTimeout(updateTimeoutDesc);
      updateTimeoutDesc = setTimeout(() => {
        updateTask("description", props.editor.getHTML());
      }, 500);
    },
  });

  if (!update) {
    tiptapNewTaskDescription = tiptapEditor;
    return;
  }
  tiptapEditTaskDescription = tiptapEditor;
};

export const initTipTapEditTaskName = (content: string) => {
  const selector = ".tiptap-edit-name";
  const tipTapEditorElm = document.querySelector(selector);
  if (!tipTapEditorElm) {
    throw new Error(`No element with class '${selector}' found`);
  }

  new Editor({
    element: tipTapEditorElm,
    extensions: [
      Node.create({
        name: "doc",
        topNode: true,
        content: "text*",
      }),
      Node.create({
        name: "text",
        group: "inline",
      }),
      Heading,
      Placeholder.configure({
        // placeholder: ({ node }) => {
        //   return "df";
        // },
      }),
    ],
    editorProps: {
      attributes: {
        class: "prose dark:prose-invert prose-2xl focus:outline-none",
        "data-placeholder-text": "Task name...",
      },
    },
    content: content,
    onUpdate(props) {
      clearTimeout(updateTimeoutName);

      updateTimeoutName = setTimeout(() => {
        updateTask("name", props.editor.getText());
      }, 1000);
    },
  });
};

export const initTipTapNewTaskName = async () => {
  let parsedDateText: string = "";

  const selector = ".tiptap-new-task-name";
  const tipTapEditorElm = document.querySelector(selector);
  if (!tipTapEditorElm) {
    throw new Error(`No element with class '${selector}' found`);
  }

  tiptapNewTaskName = new Editor({
    element: tipTapEditorElm,

    extensions: [
      Highlight.configure({
        multicolor: true,
        HTMLAttributes: {
          class: "p-1 rounded",
        },
      }),
      Node.create({
        name: "oneLiner",
        topNode: true,
        content: "block",
      }),
      Text,
      Paragraph.extend({
        addKeyboardShortcuts: () => {
          return {
            Enter: () => {
              if (
                document.querySelectorAll(".tippy-content .suggest-list")
                  .length > 0
              ) {
                return false;
              }
              addNewTask();
              return true;
            },
          };
        },
      }),
      Placeholder.configure({
        placeholder: "Task name...",
      }),
      suggestion("@", "at", null, getLabelSVGbyColor, "labels"),
      suggestion("#", "hash", null, getHashtagSVGbyColor, "projects"),
      suggestion("p", "p", prioritySuggestions, getFlagSVGbyColor),
    ],
    onUpdate(props) {
      // remove filters
      resetSidebarAttributes();

      let textContent = props.editor.getText();
      removeHighlightOnDelete(parsedDateText, textContent, tiptapNewTaskName);

      //TiptapNewTaskName.chain().unsetMark("highlight").run();

      // Clone the casual parser (or strict parser if preferred)
      const customParser = chrono.casual.clone();

      // Add a custom refiner to ensure parsed results are standalone words
      customParser.refiners.push({
        refine: (context, results) => {
          return results.filter((result) => {
            const text = context.text;
            const parsedText = result.text;

            const wordBoundaryRegex = new RegExp(`\\b${parsedText}\\b`, "i");

            return wordBoundaryRegex.test(text);
          });
        },
      });
      let parsedDate = customParser.parse(textContent);
      if (parsedDate.length <= 0) return;
      let panelDate = parsedDate[0].date();
      if (isTipTapNewTaskNameInFocus) {
        panelDatePicker.setDate(panelDate);
      }

      parsedDateText = parsedDate[0].text.trim();
      console.log(parsedDateText);
      highlightText(tiptapNewTaskName, parsedDateText);
    },
    onFocus() {
      isTipTapNewTaskNameInFocus = true;
    },
    onBlur() {
      isTipTapNewTaskNameInFocus = false;
    },
    editorProps: {
      attributes: {
        class: "prose dark:prose-invert prose-2xl mb-4 focus:outline-none",
      },
    },
  });
};

const highlightText = (editor: Editor, text: string) => {
  const { doc } = editor.state;

  const regex = new RegExp(`\\b${text}\\b`, "g");
  let match;
  const tr = editor.state.tr;

  // Remove existing highlight marks for 'highlight' mark type
  doc.descendants((node, pos) => {
    if (
      node.isText &&
      node.marks.some((mark) => mark.type.name === "highlight")
    ) {
      if (removedMark) {
        removedMark = false;
        return;
      }
      // @ts-ignore
      tr.removeMark(pos, pos + node.nodeSize, editor.schema.marks.highlight);
      removedMark = true;
    }
  });

  // Iterate through document nodes
  doc.descendants((node, pos) => {
    // Skip nodes that are not text or have a specific attribute
    if (node.isText || node.isBlock) {
      const nodeText = node.text ?? ""; // Get text content of the node
      // Search for text matches within node
      let offset = 0;

      while ((match = regex.exec(nodeText)) !== null) {
        const startPos = pos + match.index + offset;
        const endPos = startPos + text.length;

        tr.addMark(
          startPos,
          endPos,
          // @ts-ignore
          editor.schema.marks.highlight.create({
            color: "#1c64f2",
          })
        );
        offset = match.index + match[0].length - text.length; // Adjust offset for next search
      }
    }
  });

  editor.view.dispatch(tr);

  // Remove highlight mark to prevent further highlighting
  editor
    .chain()
    .setTextSelection(doc.content.size)
    .unsetMark("highlight")
    .run();
};

export const updateTiptapPanelDate = () => {
  document
    .getElementById("due-date")
    ?.addEventListener("changeDate", (e: Event) => {
      const panelDateString = (<HTMLInputElement>e.target).value;
      if (panelDateString === "") {
        document.querySelector(".tiptap mark")?.remove();
        return;
      }
      setTimeout(() => {
        const panelDate = formatDate(new Date(panelDateString), "d MMM");
        const tiptapMarkEl = document.querySelector(".tiptap mark");
        if (tiptapMarkEl !== null && !isTipTapNewTaskNameInFocus) {
          tiptapMarkEl.textContent = panelDate;
          return;
        }

        if (panelDate == formatDate(new Date(), "d MMM")) {
          return;
        }

        if (isTipTapNewTaskNameInFocus) {
          return;
        }
        tiptapNewTaskName.commands.insertContent({
          type: "text",
          text: panelDate,
          marks: [
            {
              type: "highlight",
            },
          ],
        });
        tiptapNewTaskName.commands.insertContent(" ");
      }, 100);
    });
};

const resetSidebarAttributes = () => {
  if (document.querySelectorAll(".mention-at").length <= 0)
    tomLabel.setValue("");
  if (document.querySelectorAll(".mention-hash").length <= 0)
    tomProject.setValue("");
  if (document.querySelectorAll(".mention-p").length <= 0)
    if (tomPriority) tomPriority.setValue("normal");
};

const removeHighlightOnDelete = (
  parsedDateText: string,
  textContent: string,
  TiptapNewTaskName: Editor
) => {
  if (!isTipTapNewTaskNameInFocus) return;
  const regex = new RegExp(parsedDateText, "g");

  // Remove highlight if "tomorro" is not complete
  if (!regex.test(textContent)) {
    const { doc, tr } = TiptapNewTaskName.state;

    doc.descendants((node, pos) => {
      if (node.marks.some((mark) => mark.type.name === "highlight")) {
        panelDatePicker.setDate(new Date());
        tr.removeMark(
          pos,
          pos + node.nodeSize,
          // @ts-ignore
          TiptapNewTaskName.schema.marks.highlight
        );
      }
    });

    tiptapNewTaskName.view.dispatch(tr);
  }
};

export async function addToTasks(
  name: string,
  calendarID: string,
  eventID: string
) {
  let taskStartDate = new Date(Date.now() + 1000 * 60 * 60 * 2)
    .toISOString()
    .split("T")[0];

  const newTask: Task = {
    name,
    description: "",
    panel_date: taskStartDate,
    view_type: Alpine.store("viewType") as string,
    priority: "normal",
    done: false,
    google_calendar: {
      calendar_id: calendarID,
      event_id: eventID,
    },
  };

  try {
    const response = await fetch("/api/users/tasks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newTask),
    });

    if (!response.ok) {
      throw new Error("Failed to add task");
    }

    const responseData = await response.json();
    handleHttpError(response, responseData);

    const taskColumn = document.querySelector(
      `#task-column-${taskStartDate} .task-column`
    );

    if (taskColumn) {
      taskColumn.appendChild(decodeHtmlEntities(responseData.task));
    }

    addNewTaskModal.hide();
    calcNumberOfTasks(taskStartDate);

    revalidateDailyTasks();
  } catch (error) {
    console.error("Error adding task:", error);
  }
}

export const updateTipTapNewTaskName = (content: string) => {
  tiptapNewTaskName.commands.setContent(content);
};

export const afterDeleteTask = (taskID: string) => {
  const task = document.getElementById("task-" + taskID);
  if (task) {
    task.remove();
    editTaskModal.hide();
    revalidateDailyTasks();
  }
};

export const exportTaskToMD = () => {
  tiptapEditTaskDescription.chain().focus().export({ format: "md" }).run();
};

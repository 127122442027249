import { Dropdown } from "flowbite";
import { initializeCalendar } from "./fullcalendar";

interface RightSidebarProps {
  navId: string;
  contentId: string;
  defaultTabId?: string;
}

export class RightSidebar {
  private navId: string;
  private contentId: string;

  private isOpen: boolean = false;

  constructor({ navId, contentId, defaultTabId }: RightSidebarProps) {
    this.navId = navId;
    this.contentId = contentId;

    this.init(defaultTabId);
  }

  init(defaultTabId?: string) {
    const contentContainer = document.getElementById(this.contentId);

    const tabs = document.querySelectorAll<HTMLButtonElement>(
      `#${this.navId} button[role='tab']`
    );

    if (!contentContainer || !tabs.length) {
      console.log("Right sidebar elements not found");
      return;
    }

    let currentTab: HTMLButtonElement | null = null;

    const toggleSidebar = () => {
      this.isOpen = !this.isOpen;
      contentContainer.classList.toggle("hidden", !this.isOpen);
    };

    tabs.forEach((tab) => {
      tab.addEventListener("click", () => {
        if (this.isOpen && tab === currentTab) {
          toggleSidebar();
        } else if (!this.isOpen) {
          toggleSidebar();
        }

        currentTab = tab;
      });
    });

    const googleTab = document.getElementById(`${this.navId}-google-tab`);

    if (googleTab) {
      googleTab.addEventListener("click", () => {
        initializeCalendar(
          `${this.navId}-google-calendar`,
          `${this.navId}-google-calendar-portal`
        );
      });

      const targetEl = document.getElementById("dropdown-calendar-filter");
      const triggerEl = document.getElementById("calendar-filter-button");

      if (targetEl && triggerEl) {
        const dropdown = new Dropdown(targetEl, triggerEl);

        triggerEl.addEventListener("click", () => {
          dropdown.toggle();
        });
      }
    } else {
      console.log("Google tab not found");
    }

    if (defaultTabId) {
      const defaultTab = document.getElementById(defaultTabId);

      if (defaultTab) {
        currentTab = defaultTab as HTMLButtonElement;

        toggleSidebar();

        if (defaultTabId.includes("google-tab")) {
          setTimeout(() => {
            initializeCalendar(
              `${this.navId}-google-calendar`,
              `${this.navId}-google-calendar-portal`
            );
          }, 1e3);
        }
      }
    }
  }
}

import { initActions } from "./actions";

async function getFocusModal(taskID: string): Promise<any> {
  const resp = await fetch(
    "/api/users/tasks/focus-mode?" +
      new URLSearchParams({
        id: taskID,
        view_type: String(Alpine.store("viewType")),
      }),
    {
      method: "GET",
    }
  );

  return await resp.json();
};

export async function onFocusModal(taskID: string) {
  console.log(taskID);

  const taskEditModal = await getFocusModal(taskID);

  (<HTMLElement>(
    document.querySelector("#focus-modal .modal-body")
  )).innerHTML = taskEditModal.focusMode;

  initActions("task-container-edittt", taskID);
}
